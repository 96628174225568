<template>
  <div class="lec-home">
    <div class="home-content clearfix">
      <div class="info">
        <div class="img_avater">
          <img
            class="info-img"
            v-if="lecInfo.avatar"
            :src="lecInfo.avatar"
          />
          <img v-else class="" src="@/assets/per_img.png" />
        </div>
        <div class="info-name">{{ lecInfo.name }}</div>
        <div class="reserve_btn" @click="reserveBtn()" v-if="reserveState">预约讲师</div>
        <!-- <div class="marks">
          <div class="mark">{{ lecInfo.level }}</div>
        </div> -->
        <div class="tags">
          <div class="tag" v-for="mark in lecInfo.tags" :key="mark">
            {{ mark }}
          </div>
        </div>
        <div class="introduction" v-if="lecInfo.teach_direction && lecInfo.teach_direction.length>0">可定制授课方向：<span v-for="(item, index) in lecInfo.teach_direction" :key="item">{{item}}<span v-if="index+1<lecInfo.teach_direction.length">、</span></span></div>
        <div class="introduction">{{ lecInfo.introduce }}</div>
        <!-- <ul class="awards-list" v-if="lecInfo.honor.length > 0">
          <li class="awards" v-for="honor in lecInfo.honor">{{ honor }}</li>
        </ul> -->
      </div>
      <ul class="course-list" v-if="courseList.data&&courseList.data.length>0"> 
        <li
          class="course-info"
          v-for="item in courseList.data"
          :key="item.id"
          @click="$router.push('/recordedInfo/' + item.id)"
        >
          <img class="course-img" :src="item.image" />
          <div class="course-content">
            <div class="course-title">
              {{ item.name
              }}<span class="class-hour">{{ item.duration }}课时间</span>
            </div>
            <div class="marks">
              <div class="mark" v-for="mark in item.course_tags" :key="mark">
                {{ mark }}
              </div>
            </div>
            <div class="course-description">{{ item.intro }}</div>
            <div class="course-amount">￥{{ item.price }}</div>
          </div>
        </li>
        <li>
          <pagination
            v-if="num > 0"
            :num="num"
            :limit="limit"
            @getNew="getNew"
          ></pagination>
        </li>
      </ul>
      <ul class="course-list" v-else>
        <div class="no-data">
          暂无相关课程
        </div>
      </ul>
    </div>
  </div>
</template>
<script>
import pagination from "@/components/pc/pagination.vue";
export default {
  name: "LecHome",
  data() {
    return {
      lecId: "", //讲师id
      lecInfo: "", //讲师详情
      courseList: "", //讲师课程
      num: 0, //数据总个数
      limit: 10, //一页显示几个
      nowPage: 1, //当前页码
      reserveState:false,
    };
  },
  components: {
    pagination,
  },
  mounted() {
    this.lecId = this.$route.params.id;
    this.getLecInfo(); //获取详情
    this.getCourseList(); //获取课程
    this.siteInfo = JSON.parse(localStorage.getItem('siteInfo'))
    if(this.siteInfo && this.siteInfo.is_teacher_subscribe_form==1){
      this.reserveState = true
    }
  },
  methods: {
    // 跳转到预约表单
    reserveBtn(){
      this.$router.push('/company_register')
    },
    //获取详情
    getLecInfo() {
      this.$axios.post(`/v1/teacher/detail`, { id: this.lecId }).then((res) => {
        this.lecInfo = res.data;
      });
    },
    //获取课程
    getCourseList() {
      this.$axios
        .post(`/v1/teacher/courseList`, {
          id: this.lecId,
          page: this.nowPage,
          pre_page: this.limit,
        })
        .then((res) => {
          this.num = res.data.total;
          this.courseList = res.data;
        });
    },
    //翻页
    getNew(nowPage) {
      this.nowPage = nowPage;
      this.getCourseList();
    },
  },
};
</script>
<style scoped lang="scss">
.lec-home {
  background-color: #f7f7f7;
  padding: 34px 0 88px;
}
.home-content {
  width: 1200px;
  margin: 0 auto;
}
.marks {
  .mark {
    display: inline-block;
    background: rgba(255, 195, 11, 0.14);
    border-radius: 133px 3px 133px 3px;
    color: #f5a623;
    float: none;
  }
}
.info {
  box-sizing: border-box;
  width: 352px;
  min-height: 642px;
  padding: 40px 30px 0 30px;
  background: #fff;
  float: left;
  .img_avater {
    width: 150px;
    height: 150px;
    overflow: hidden;
    margin: 0 auto 20px;
    border-radius: 100%;

    .info-img {
      width: 100%;
      // height: 100%;

      // border-radius: 100%;
    }
  }

  .info-name {
    font-size: 24px;
    color: #333;
    text-align: center;
    margin-bottom: 10px;
  }
  .reserve_btn{
    width: 120px;
    height: 40px;
    margin: 0 auto;
    text-align: center;
    background: #254ed4;
    color: #fff;
    font-size: 14px;
    line-height: 40px;
    border-radius: 5px;
    cursor: pointer;
  }
  .marks {
    text-align: center;
    margin-bottom: 10px;
  }
  .tags {
    overflow: hidden;
    margin-bottom: 20px;
    .tag {
      padding: 2px 5px;
      background: rgba(37, 78, 212, 0.14);
      border-radius: 2px;
      border: 1px solid rgba(37, 78, 212, 0.5);
      font-size: 10px;
      color: #254ed4;
      float: left;
      margin: 0 17px 11px 0;
    }
  }
  .introduction {
    font-size: 14px;
    color: #666;
    line-height: 19px;
    margin-bottom: 24px;
  }
  .awards-list {
    padding-top: 23px;
    border-top: 1px solid #e5e5e5;
    .awards {
      font-size: 14px;
      color: #666;
      margin-bottom: 10px;
    }
  }
}
.course-list {
  box-sizing: border-box;
  width: 818px;
  padding: 24px;
  background-color: #fff;
  float: right;
  box-shadow: 0px 0px 4px 0px rgba(0, 12, 40, 0.14);
  .course-info {
    padding: 10px;
    margin-bottom: 4px;
    overflow: hidden;
    &:hover {
      box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.14);
      border-radius: 6px;
      cursor: pointer;
    }
  }
  .course-img {
    width: 282px;
    height: 156px;
    border-radius: 8px;
    float: left;
    margin-right: 24px;
  }
  .course-content {
    width: 380px;
    height: 156px;
    float: left;
    position: relative;
  }
  .course-title {
    font-size: 16px;
    font-weight: 900;
    color: #333;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .class-hour {
      font-size: 12px;
      color: #666;
      margin-left: 10px;
    }
  }
  .marks {
    margin-bottom: 10px;
  }
  .course-description {
    font-size: 14px;
    color: #999;
    //多行省略号
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    margin-bottom: 16px;
  }
  .course-amount {
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    font-weight: 900;
    color: #cd1b14;
    position: absolute;
    left: 0;
    bottom: 0;
  }
  .pay {
    font-size: 12px;
    color: #999;
  }
}
.no-data{
  text-align: center;
  font-size: 16px;
  color: #999;
}
</style>